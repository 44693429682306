import React from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import GridCell from "./components/GridCell";

export default function ResultsTable({
  submitted,
  handleSortColumnHeaderClick,
  sortColumns,
  setSortColumns,
  filteredData,
}) {
  return (
    <Grid
      container
      spacing={0}
      style={{
        filter: !submitted ? "blur(4px)" : "none",
      }}
    >
      <Grid
        item
        xs={4}
        sm={4}
        sx={{
          height: "48px",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>Estate Agent</Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          height: "48px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 500 }}
          onClick={() =>
            handleSortColumnHeaderClick(
              "numberOfProperties",
              sortColumns,
              setSortColumns
            )
          }
        >
          Local Market Share
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          height: "48px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 500 }}
          onClick={() =>
            handleSortColumnHeaderClick(
              "successRate",
              sortColumns,
              setSortColumns
            )
          }
        >
          Success Rate
        </Typography>
      </Grid>
      
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          height: "48px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 500 }}
          onClick={() =>
            handleSortColumnHeaderClick(
              "avgDaysToSoldStc",
              sortColumns,
              setSortColumns
            )
          }
        >
          Avg. Days to Sell
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          height: "48px",
          borderBottom: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 500 }}
          onClick={() =>
            handleSortColumnHeaderClick(
              "avgAskPrice",
              sortColumns,
              setSortColumns
            )
          }
        >
          Avg. Asking Price
        </Typography>
      </Grid>

      {filteredData &&
        filteredData.map((property) => {
          const brandBranch = property.brandBranch;

          const marketShare = property.marketShare.right
            ? property.marketShare.right.toFixed(1) + "%"
            : "-";

          const numberOfProperties = property.numberOfProperties;
          const rowColor = numberOfProperties <= 5 ? "#ddd" : "initial";

          const successRate =
            property.successRate && property.successRate._tag === "Some"
              ? (property.successRate.value * 100).toFixed(0) + "%"
              : "-";

          const avgDaysToSoldStc =
            property.avgDaysToSoldStc &&
            property.avgDaysToSoldStc._tag === "Some"
              ? property.avgDaysToSoldStc.value.toFixed(0)
              : "-";

          const avgAskPrice =
            property.avgAskPrice.value &&
            "£" +
              (
                Math.round(property.avgAskPrice.value / 1000) * 1000
              ).toLocaleString("en-UK", { maximumFractionDigits: 0 });

          const marketShareAndNumberOfProperties = `${marketShare} (${numberOfProperties} properties)`;

          return (
            <React.Fragment key={property.rightmoveId}>
              <GridCell
                cellData={brandBranch}
                cols={4}
                textAlign="flex-start"
                color={rowColor}
              />
              <GridCell
                cellData={marketShareAndNumberOfProperties}
                cols={2}
                color={rowColor}
              />
              <GridCell cellData={successRate} cols={2} color={rowColor} />
              <GridCell cellData={avgDaysToSoldStc} cols={2} color={rowColor} />
              <GridCell cellData={avgAskPrice} cols={2} color={rowColor} />
            </React.Fragment>
          );
        })}
    </Grid>
  );
}

ResultsTable.propTypes = {
  submitted: PropTypes.bool.isRequired,
  handleSortColumnHeaderClick: PropTypes.func.isRequired,
  sortColumns: PropTypes.array.isRequired,
  setSortColumns: PropTypes.func.isRequired,
  filteredData: PropTypes.arrayOf(
    PropTypes.shape({
      rightmoveId: PropTypes.number.isRequired,
      brandBranch: PropTypes.string.isRequired,
      marketShare: PropTypes.shape({
        right: PropTypes.number,
      }),
      numberOfProperties: PropTypes.number.isRequired,
      successRate: PropTypes.shape({
        _tag: PropTypes.string.isRequired,
        value: PropTypes.number,
      }),
      avgDaysToSoldStc: PropTypes.shape({
        _tag: PropTypes.string.isRequired,
        value: PropTypes.number,
      }),
      avgAskPrice: PropTypes.shape({
        value: PropTypes.number,
      }),
    })
  ),
};
