import React from "react";
import { CircularProgress } from "@mui/material";
export default function LoadingSpinner( submitted ) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: submitted ? -1 : 1,
      }}
    >
      <CircularProgress />
    </div>
  );
}
