import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

export default function UserDetailsDialog({
  submitted,
  handleFormSubmit,
  email,
  setEmail,
}) {
  return (
    <Dialog
      open={!submitted}
      onClose={null}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Enter your email to view results
      </DialogTitle>
      <form onSubmit={handleFormSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

UserDetailsDialog.propTypes = {
  submitted: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
};
