import React, { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

import {
  fetchEstateAgentFormData,
  fetchFlypDataWithoutForm,
  handleSortColumnHeaderClick,
  sortEstateAgentData,
} from "./functions/bestEstateAgentHelpers";

import LoadingSpinner from "./LoadingSpinner";
import ResultsGrid from "./ResultsGrid";
import UserDetailsDialog from "./UserDetailsDialog";

function BestEstateAgent() {
  //eslint-disable-next-line
  const [data, setData] = useState(null);
  //eslint-disable-next-line
  const [token, setToken] = useState(null);
  const [sortColumns, setSortColumns] = useState(["marketShare"]);
  const [flypData, setFlypData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
  };

  useEffect(() => {
    // Step 5: Retrieve the token from the URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
console.log(token);
    // Step 6: Send a GET or POST request with the token to retrieve the form data
    if (token) {
      setToken(token);
      fetchEstateAgentFormData(token, setData, setFlypData, setLoading);
    } else {
      fetchFlypDataWithoutForm(setData);
    }
  }, []);

  useEffect(() => {
    if (flypData) {
      const filteredData = getFilteredData(flypData, sortColumns);
      setFilteredData(filteredData);
    }
  }, [flypData, sortColumns]);

  const getFilteredData = (data, sortColumns) => {
    //eslint-disable-next-line
    const sortedData = data.sort((a, b) => {
      // Sorting logic as before...
    });

    let filteredData;

    if (sortColumns.includes("avgDaysToSoldStc")) {
      const marketShareFilteredData = sortedData.filter(
        (property) => property.marketShare.right > 0
      );

      const numberOfResults = marketShareFilteredData.length;
      filteredData = sortedData.slice(0, numberOfResults);
    } else {
      filteredData = sortedData.filter(
        (property) => property.marketShare.right > 0
      );
    }
    //console.log(filteredData);
    return filteredData;
  };

  useEffect(() => {
    if (flypData) {
      Object.defineProperty(flypData, "__hidden__", { value: true });
    }
  }, [flypData]);

  useEffect(() => {
    if (flypData) {
      // Sort data by 'marketShare' on load
      const sortedData = sortEstateAgentData(flypData, sortColumns);
      setFlypData(sortedData);

      const filteredData = getFilteredData(sortedData, sortColumns);
      setFilteredData(filteredData);
    }
  }, [flypData]);

  //eslint-disable-next-line
  const sortedData = flypData && sortEstateAgentData(flypData, sortColumns);
  const theme = useTheme();
  //eslint-disable-next-line
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <ResultsGrid
        submitted={submitted}
        handleSortColumnHeaderClick={handleSortColumnHeaderClick}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        filteredData={filteredData}
      />
      {!submitted && (
        <UserDetailsDialog
          submitted={submitted}
          handleFormSubmit={handleFormSubmit}
          email={email}
          setEmail={setEmail}
        />
      )}
      {loading && <LoadingSpinner submitted={submitted} />}
    </>
  );
}

export default BestEstateAgent;
