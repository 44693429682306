import axios from "axios";
import CryptoJS from "crypto-js";

//console.log("Encryption key from environment variable:", process.env.REACT_APP_ENCRYPTION_KEY);

const hostname = window.location.hostname;
const Buffer = require('buffer').Buffer;
const encryptionKey = Buffer.from(
  "a6b23c6d8e4fae53f1a24fddc1d8e9fe4a1db0a4d4c4e8f8a0b2c3e1f2a2b4c5",
  "hex"
);

// Helper function to decrypt JSON data
function decryptJSON(encryptedJSON) {
  const bytes = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(encryptedJSON.data) },
    CryptoJS.enc.Hex.parse(encryptionKey.toString("hex")), // Update this line
    { iv: CryptoJS.enc.Hex.parse(encryptedJSON.iv) }
  );

  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}

export const fetchEstateAgentFormData = async (
  token,
  setData,
  setFlypData,
  setLoading
) => {
  try {
    let retrieveDataUrl;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      retrieveDataUrl = `http://localhost:3005/server.js?token=${token}`;
    } else {
      retrieveDataUrl = `https://us-central1-react-wordpress-32f46.cloudfunctions.net/retrieveData?token=${token}`;
    }

    const response = await fetch(retrieveDataUrl);

    if (!response.ok) {
      console.error(`Fetch error: ${response.status} ${response.statusText}`);
      return;
    }

    // Step 7: Receive the form data associated with the token
    const data = await response.json();
    //console.log(data);
    if (!data) {
      console.error("Error: No data received");
      return;
    }

    if (typeof data === "object" && data !== null) {
      setData(data);
    } else {
      console.error("Error: Fetched data is not an object");
    }

    // Step 8: Send a request to the /flyp endpoint with the form data
    console.log(data);
    fetchFlypDataWithForm(data, setFlypData, setLoading);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const fetchFlypDataWithForm = async (
  formData,
  setFlypData,
  setLoading
) => {
  console.log(formData)
  try {
    let flypUrl;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      flypUrl = "http://localhost:3005/flyp";
    } else {
      flypUrl = "https://us-central1-react-wordpress-32f46.cloudfunctions.net/flyp";
    }

    //const response = await fetch("http://localhost:3005/flyp", {
    const response = await fetch(flypUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      console.error(`Fetch error: ${response.status} ${response.statusText}`);
      return;
    }

    const flypData = await response.json();
    //setFlypData(flypData); // Add this line
    //console.log("Flyp data:", flypData);

    // Decrypt the flypData
    const decryptedFlypData = decryptJSON(flypData);
    const flypDataFlypRemoved = decryptedFlypData.filter(
      (property) => property.rightmoveId !== 0
    ); // remove Flyp
    setFlypData(flypDataFlypRemoved);

    //console.log("Flyp data:", decryptedFlypData);
    setLoading(false); // Add this line

    // Process flypData as needed
  } catch (error) {
    console.error("Error fetching flyp data:", error);
  }
};
export const fetchFlypDataWithoutForm = async (setData) => {
  let fetchFlypDataWithoutFormUrl;
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    fetchFlypDataWithoutFormUrl = "http://localhost:3005/flyp";
  } else {
    fetchFlypDataWithoutFormUrl =
      "https://us-central1-react-wordpress-32f46.cloudfunctions.net/flyp";
  }

  try {
    const response = await axios.get(fetchFlypDataWithoutFormUrl);
    if (Array.isArray(response.data)) {
      setData(response.data);
    } else {
      console.error("Error: Fetched data is not an array");
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleSortColumnHeaderClick = (
  column,
  sortColumns,
  setSortColumns
) => {
  if (sortColumns.includes(column)) {
    setSortColumns(sortColumns.filter((col) => col !== column));
  } else {
    setSortColumns([column]);
  }
};

export const sortEstateAgentData = (flypData, sortColumns) => {
  return flypData.sort((a, b) => {
    for (let sortColumn of sortColumns) {
      switch (sortColumn) {
        // Number of Properties Case
        case "numberOfProperties": {
          const numOfPropsA = a.numberOfProperties || 0;
          const numOfPropsB = b.numberOfProperties || 0;
        
          if (numOfPropsB !== numOfPropsA) {
            return numOfPropsB - numOfPropsA;
          }
          break;
        }
        
        // Number of Properties Case
        // Success Rate Case
        case "successRate":
          // Compare the value if both elements have the _tag "None"
          if (
            a.successRate &&
            a.successRate._tag === "None" &&
            b.successRate &&
            b.successRate._tag === "None"
          ) {
            return 0;
          }
          // If the first element (a) has the _tag "None"
          else if (a.successRate && a.successRate._tag === "None") {
            return 1;
          }

          // If the second element (b) has the _tag "None"
          else if (b.successRate && b.successRate._tag === "None") {
            return -1;
          }

          // Compare the value if both elements have the _tag "Some"
          else {
            if (
              a.successRate &&
              a.successRate._tag === "Some" &&
              a.successRate.value &&
              b.successRate &&
              b.successRate._tag === "Some" &&
              b.successRate.value
            ) {
              return b.successRate.value - a.successRate.value;
            } else if (
              a.successRate &&
              a.successRate._tag === "None" &&
              b.successRate &&
              b.successRate._tag === "None"
            ) {
              return 0;
            } else if (a.successRate && a.successRate._tag === "None") {
              return 1;
            } else if (b.successRate && b.successRate._tag === "None") {
              return -1;
            }
          }

          break;

        // Days To avgDaysToUnderOffer Case
        case "avgDaysToUnderOffer":
          // Compare the value if both elements have the _tag "None"
          if (
            a.avgDaysToUnderOffer &&
            a.avgDaysToUnderOffer._tag === "None" &&
            b.avgDaysToUnderOffer &&
            b.avgDaysToUnderOffer._tag === "None"
          ) {
            return 0;
          }
          // If the first element (a) has the _tag "None"
          else if (
            a.avgDaysToUnderOffer &&
            a.avgDaysToUnderOffer._tag === "None"
          ) {
            return 1;
          }

          // If the second element (b) has the _tag "None"
          else if (
            b.avgDaysToUnderOffer &&
            b.avgDaysToUnderOffer._tag === "None"
          ) {
            return -1;
          }

          // Compare the value if both elements have the _tag "Some"
          // Compare the value if both elements have the _tag "Some"
          else {
            if (
              a.avgDaysToUnderOffer &&
              a.avgDaysToUnderOffer._tag === "Some" &&
              a.avgDaysToUnderOffer.value &&
              b.avgDaysToUnderOffer &&
              b.avgDaysToUnderOffer._tag === "Some" &&
              b.avgDaysToUnderOffer.value
            ) {
              return a.avgDaysToUnderOffer.value - b.avgDaysToUnderOffer.value; // This will ensure low to high sorting
            }
          }

          break;

        // Days To Sold STC Case
        case "avgDaysToSoldStc":
          // Compare the value if both elements have the _tag "None"
          if (
            a.avgDaysToSoldStc &&
            a.avgDaysToSoldStc._tag === "None" &&
            b.avgDaysToSoldStc &&
            b.avgDaysToSoldStc._tag === "None"
          ) {
            return 0;
          }
          // If the first element (a) has the _tag "None"
          else if (a.avgDaysToSoldStc && a.avgDaysToSoldStc._tag === "None") {
            return 1;
          }

          // If the second element (b) has the _tag "None"
          else if (b.avgDaysToSoldStc && b.avgDaysToSoldStc._tag === "None") {
            return -1;
          }

          // Compare the value if both elements have the _tag "Some"
          // Compare the value if both elements have the _tag "Some"
          else {
            if (
              a.avgDaysToSoldStc &&
              a.avgDaysToSoldStc._tag === "Some" &&
              a.avgDaysToSoldStc.value &&
              b.avgDaysToSoldStc &&
              b.avgDaysToSoldStc._tag === "Some" &&
              b.avgDaysToSoldStc.value
            ) {
              return a.avgDaysToSoldStc.value - b.avgDaysToSoldStc.value; // This will ensure low to high sorting
            }
          }

          break;

        // Days To avgAskPrice Case
        case "avgAskPrice":
          // Compare the value if both elements have the _tag "None"
          if (
            a.avgAskPrice &&
            a.avgAskPrice._tag === "None" &&
            b.avgAskPrice &&
            b.avgAskPrice._tag === "None"
          ) {
            return 0;
          }
          // If the first element (a) has the _tag "None"
          else if (a.avgAskPrice && a.avgAskPrice._tag === "None") {
            return 1;
          }

          // If the second element (b) has the _tag "None"
          else if (b.avgAskPrice && b.avgAskPrice._tag === "None") {
            return -1;
          }

          // Compare the value if both elements have the _tag "Some"
          // Compare the value if both elements have the _tag "Some"
          else {
            if (
              a.avgAskPrice &&
              a.avgAskPrice._tag === "Some" &&
              a.avgAskPrice.value &&
              b.avgAskPrice &&
              b.avgAskPrice._tag === "Some" &&
              b.avgAskPrice.value
            ) {
              return a.avgAskPrice.value - b.avgAskPrice.value; // This will ensure low to high sorting
            }
          }

          break;

        // Add more cases here as needed

        default:
          break;
      }
    }

    return 0;
  });
};
