import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

export default function GridCell({ cellData, cols, textAlign, color }) {
  const justifyContent = textAlign === "flex-start" ? "flex-start" : "center";

  return (
    <Grid
      item
      xs={cols}
      sm={cols}
      sx={{
        height: "64px",
        lineHeight: "22px",
        borderBottom: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent,
        color: color,
      }}
    >
      {cellData}
    </Grid>
  );
}

GridCell.propTypes = {
  cellData: PropTypes.node.isRequired,
  cols: PropTypes.number.isRequired,
  textAlign: PropTypes.oneOf(["flex-start", "center"]),
  color: PropTypes.string.isRequired,
};
